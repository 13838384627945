<template>
  <div v-resize="onResize">
    <!-- Header -->
    <header>
      <nav class="navbar navbar-expand-lg navbar-primary p-0" v-bind:class="{ 'collapser': hidde_menu_class }">
        <div class="container-fluid d-block px-3">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-10 col-xl-10 d-flex align-items-center">
              <v-btn class="btn-menu-nav d-lg-none bg-white" @click="hidde_menu_class = !hidde_menu_class">
                <i class="mdi mdi-menu text-info fz-22" v-if="hidde_menu_class"></i>
                <i class="mdi mdi-close text-info fz-22" v-if="!hidde_menu_class"></i>
              </v-btn>
              
              <core-menu-superior></core-menu-superior>

            </div>
            <div class="col-2 col-xl-2 d-none d-lg-flex align-items-center justify-content-end">
              <div class="notification-header">
                <v-menu offset-y>
                  <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn fab text small color="standar" v-bind="attrs" v-on="on">
                      <v-badge color="primary" content="6">
                        <v-icon>mdi-bell-outline</v-icon>
                      </v-badge>
                    </v-btn>
                  </template> -->
                  <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div class="notification-header ml-3 ml-lg-4 ml-xl-5">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" class="profile d-flex align-items-center dropdown-toggle fw-500"  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <v-avatar color="white" class="p-1">
                        <v-badge color="success">
                          <img src="http://produccionsitios.com:9801/apic/webroot/themes/admin/assets/img/avatar-sign.png" class="rounded-circle" alt="">
                        </v-badge>
                      </v-avatar>
                      <span class="d-none d-md-block">{{nombre_usuario}}</span>
                    </a>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="fw-600">
                        {{nombre_usuario}} - {{tipo_usuario}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="fw-600">                        
                        <router-link :to="{ name: 'profile', params: { userId: userId } }">Mi perfil</router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="fw-600">
                        <a :href="url_cerrarsession">Salir</a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <!-- Menú -->
    <div class="nav-fixed" id="nav-fixed" v-bind:class="{ 'collapser': hidde_menu_class }">
    
      <div class="box-logo">
        <router-link to="">
          <img src="../assets/images/logo-login-alt.png" alt="" class="complete" />
          <img src="../assets/images/logo-login-min-alt.png" alt="" class="letter" />
        </router-link>
      </div>

      <div class="scroll-nav-fixed" data-simplebar>
        
        <core-menu></core-menu>
      </div>
    </div>

    <div class="content-section" v-bind:class="{ 'collapser': hidde_menu_class, 'only-content': only_content }">
      <router-view v-on:reloadpage="reloadpage" :key="idPage"></router-view>
    </div>
  </div>
</template>
<script>
import { EventSystem } from '../libs/Events';
import Helpers from '../helpers/Helpers';
import Session from '../helpers/Session';
import SessionUser from "../libs/SessionUser";

export default {
  name: 'MainLayout',
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0
      },
      idPage: 1,
      collapser: true,
      hidde_menu_class: true,
      hidde_nav_class: true,
      only_content: false,
      panel: false,
      subpanel: false,
      url_perfil: '',
      url_cerrarsession: '',
      nombre_usuario: '',
      tipo_usuario: '',
      items: [
        { title: 'Action 1' },
        { title: 'Action 2' },
        { title: 'Action 3' },
        { title: 'Action 4' },
      ],
    };
  },

  created() {
    this.initializeUserData();
    this.setupEventListeners();
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  // beforeDestroy() {
  //   window.removeEventListener('resize', this.onResize);
  //   EventSystem.off('toggle-menu');
  // },

  methods: {
    initializeUserData() {
      const sessionUser = new SessionUser();
      this.loadUserInfo(sessionUser);
      this.setupMenuState();
    },
    loadUserInfo(sessionUser) {
  console.log('Iniciando loadUserInfo');
  console.log('SessionUser object:', sessionUser);

  // Intenta obtener el userId
  const userId = sessionUser.getUserId();
  console.log('User ID obtenido:', userId);

  // Guarda el userId en el componente
  this.userId = userId;

  this.nombre_usuario = sessionUser.getNamePublic();
  this.tipo_usuario = sessionUser.getTypeUser();
  
  // Modifica la URL del perfil para usar router-link con el userId
  this.url_perfil = { name: 'profile', params: { userId: this.userId } };
  this.url_cerrarsession = Helpers.urlInterna('api/crcore/cerrarsession');

  console.log('Datos del usuario cargados:');
  console.log('User ID:', this.userId);
  console.log('User Type:', this.tipo_usuario);
  console.log('User Name:', this.nombre_usuario);

  // Verifica si los métodos existen
  console.log('getUserId exists:', typeof sessionUser.getUserId === 'function');
  console.log('getNamePublic exists:', typeof sessionUser.getNamePublic === 'function');
  console.log('getTypeUser exists:', typeof sessionUser.getTypeUser === 'function');

  this.only_content = Session.get('auto_login_activated') === 'TRUE';
},
    setupMenuState() {
      // Nota: Mantenemos esto con Session ya que no parece estar en SessionUser
      const menuState = Session.get('menu_opened');
      if (menuState === 'TRUE') {
        EventSystem.emit('toggle-menu', {});
      }
    },

    setupEventListeners() {
      EventSystem.on('toggle-menu', () => {
        this.hidde_menu_class = !this.hidde_menu_class;
      });
    },

    onResize() {
      this.windowSize.x = window.innerWidth;
      this.windowSize.y = window.innerHeight;

      if (this.windowSize.x < 991) {
        this.hidde_menu_class = false;
      } else {
        this.hidde_menu_class = true;
      }
    },

    reloadpage() {
      this.idPage += 1;
    },

    closeAccordion() {
      if (this.windowSize.x < 992) {
        this.collapser = false;
      }
      this.panel = false;
    },

    closeSubAccordion() {
      if (this.windowSize.x < 992) {
        this.collapser = true;
      }
      this.panel = false;
      this.subpanel = false;
    }
  },
};
</script>