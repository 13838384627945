<template>
  <div>
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Contacto</span>

        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Empresa</span>

        <!-- <v-icon class="fz-34 text-white">mdi-factory</v-icon> -->
        <img src="../../_core/images/iconos.png" clasS="icono-family2" alt="">
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Portafolio</span>

        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <section class="px-3 pb-3 pt-4 mt-5 section-public min-vh-100">
      <img width="100%" class="bg-company"  />

      <div class="row justify-content-center pt-3 pt-md-4 position-relative pb-5 pb-sm-0 pb-lg-5">
        <div class="col-sm-12 col-md-10 col-lg-9">
          <template v-for="(item, i) in list_briefcases">
            <div class="row justify-content-center justify-content-sm-around align-items-center flex-column" :key="i">
              <div class="col-sm-12 col-lg-12">
                <h3 class="custom-danger--text fz-24 mt-0 fw-900 mb-0 text-center" v-if="item.title">{{item.title}}</h3>
              </div>

              <div class="col-12 col-lg-12 mt-3" v-if="item.image">
                <img max-width="100%" width="100%" class="d-block mx-auto" :src="File.fileUrl(item.image)" />
              </div>

              <div class="col-12 col-lg-12 mt-4 mt-sm-0 mt-lg-4">
                <div :class="item.description ? '' : 'invisible'">
                  <div :class="item.show_more ? 'text-center mb-0 fw-500 text-dark-standar fz-16 text-show-more' : 'text-center mb-0 fw-500 text-dark-standar fz-16'" ref="text_more">
                    {{item.description}}
                  </div>
                </div>

                <v-btn @click="toggleText(i)" v-if="item.show_more && item.description.length > 158" color="custom-danger" text class="d-table mx-auto">Leer más +</v-btn>
                <v-btn @click="toggleText(i)" v-if="!item.show_more && item.description.length > 158" color="custom-danger" text class="d-table mx-auto">Leer menos -</v-btn>

                <div class="d-flex align-items-center justify-content-center mx-auto mt-2" v-if="item.link && item.link_name">
                  <v-btn :href="item.link" color="custom-danger" class="d-flex align-items-center justify-content-center text-center" target="_blank">{{item.link_name}}</v-btn>
                </div>
              </div>
            </div>

            <hr class="line-danger my-5" :key="'separator-'+i">
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import BriefcaseController from '../controllers/BriefcaseController';

export default {
  name: 'BriefcaseView',
  data() {
    return {
      Helpers,
      File,

      header: 'briefcase',
      list_briefcases: [],
      contact_id: null,

      briefcase_controller: null
    };
  },

  mounted() {
    this.briefcase_controller = new BriefcaseController(this);
  },

  methods: {
    toggleText(position) {
      this.briefcase_controller.toggleText(position);
    }
  }
}
</script>