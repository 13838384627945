<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Empresa</li>
            <li>Editar empresa</li>
          </ul>
        </div>
        
        <div class="title">
          <h1 class="my-0 fw-700">Editar empresa</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form
              class="w-100"
              ref="form_company"
              method="post"
              v-model="form_valid"
              lazy-validation
              @submit.prevent="updateCompany"
            >
              <div class="row justify-content-center">
                <div class="col-12">
                  <v-text-field
                    filled
                    label="Nombre de la empresa"
                    :rules="[rules.requerido]"
                    v-model="name"
                    disabled
                  ></v-text-field>
                </div>
                <div class="col-12" v-if="!photo">
                  <!-- <v-file-input
                    filled
                    label="Imagen de la empresa"
                    v-model="upload_photo"
                    :prepend-icon="null"
                    @change="uploadPhoto($event)"
                  ></v-file-input> -->
                  <v-file-input 
                  filled 
                  label="Imagen de la empresa" 
                  :prepend-icon="null"
                  v-model="upload_photo"  
                  @change="uploadPhoto($event)">
                  </v-file-input>
                </div>
                <div class="col-12 col-md-8" v-if="photo">
                  <img :src="File.fileUrl(photo)" alt="" class="d-block mx-auto" />
                  <v-btn class="mt-3 mb-4 mx-auto d-table" color="danger" @click="deletePhoto">
                    Eliminar foto
                  </v-btn>
                </div>
              </div>
              <template v-for="(v, i) in list_address">
                <div :key="i" class="row">
                  <div class="col-md-6">
                    <v-text-field
                      filled
                      label="Dirección"
                      :rules="[rules.requerido]"
                      v-model="list_address[i].address"
                    ></v-text-field>
                  </div>
                  <div :class="i == 0 ? 'col-md-6' : 'col-md-5'">
                    <v-text-field
                      filled
                      label="Enlace Google Maps"
                      :rules="[rules.requerido]"
                      type="url"
                      v-model="list_address[i].google_maps_link"
                    ></v-text-field>
                  </div>
                  <div class="col-md-1" v-if="i >= 1">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                                                <v-btn
                          icon
                          x-large
                          color="danger"
                          class="mt-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteLocation(i)"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar ubicación</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>

              <div class="row justify-content-start">
                <div class="col-auto">
                  <div
                    class="cursor-pointer d-flex align-items-center justify-content-start flex-wrap mb-5"
                    @click="addLocation"
                  >
                    <v-btn color="primary" class="px-0 mr-3" rounded height="45" min-width="45">
                      <v-icon class="fz-28">mdi-plus</v-icon>
                    </v-btn>
                    <span class="fw-700 fz-18">Añadir otra ubicación</span>
                  </div>
                </div>
                <div class="col-12"></div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Horario de atención"
                    :rules="[rules.requerido]"
                    v-model="scheduled"
                  ></v-text-field>
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Enlace Facebook"
                    type="url"
                    v-model="facebook"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Enlace Instagram"
                    type="url"
                    v-model="instagram"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Enlace Twitter"
                    type="url"
                    v-model="twitter"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Enlace Linkedin"
                    type="url"
                    v-model="linkedin"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Enlace Youtube"
                    type="url"
                    v-model="skype"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Correo electrónico"
                    type="url"
                    v-model="email"
                    :rules="[rules.requerido, rules.email]"
                  ></v-text-field>
                </div>
                <div class="col-md-12">
                  <span class="fw-700 fz-18">Botones</span>
                  <hr />
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="URL 1" type="url" v-model="url1"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Descripción"
                    type="url"
                    v-model="descripcion1"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="URL 2" type="url" v-model="url2"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Descripción"
                    type="url"
                    v-model="descripcion2"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="URL 3" type="url" v-model="url3"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Descripción"
                    type="url"
                    v-model="descripcion3"
                  ></v-text-field>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <v-btn color="primary" @click="openDialog">Ver información de la empresa</v-btn>
                </div>
              </div>

              <v-btn
                class="d-table mx-auto mt-4"
                type="submit"
                color="success"
                min-width="150"
                rounded
              >
                Actualizar
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_detail[0]" width="330">
          <v-card>
            <v-card-text class="scroll-modal-quotations section-public p-3">
              <!-- <img width="100%" class="bg-company" src="../../_core/images/bg-empresa.jpg" /> -->

              <div class="title d-flex align-items-center justify-content-between flex-wrap">
                <h1 class="my-0"></h1>
                <v-btn icon color="danger" @click="closeDetail">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <div class="row justify-content-center position-relative">
                <div class="col-sm-12 col-md-10 col-lg-9">
                  <div class="row justify-content-around align-items-center flex-lg-column">
                    <div class="col-12" v-if="photo">
                      <img
                        max-width="250"
                        width="100%"
                        class="d-block mx-auto"
                        :src="File.fileUrl(photo)"
                      />
                    </div>

                    <div class="col-12 mt-5 mt-sm-0 mt-lg-5">
                      <div
                        class="d-flex justify-content-center align-items-center mb-0"
                        v-if="twitter || facebook || linkedin || skype || email"
                      >
                        <a
                          :href="twitter"
                          class="d-block px-1"
                          target="_blank"
                          v-if="twitter"
                        >
                          <img
                            max-width="32"
                            width="32"
                            height="32"
                            class="d-block mx-auto"
                            src="../../_core/images/twitter.png"
                          />
                        </a>
                        <a
                          :href="facebook"
                          class="d-block px-1"
                          target="_blank"
                          v-if="facebook"
                        >
                          <img
                            max-width="32"
                            width="32"
                            height="32"
                            class="d-block mx-auto"
                            src="../../_core/images/facebook.png"
                          />
                        </a>
                        <a
                          :href="linkedin"
                          class="d-block px-1"
                          target="_blank"
                          v-if="linkedin"
                        >
                          <img
                            max-width="32"
                            width="32"
                            height="32"
                            class="d-block mx-auto"
                            src="../../_core/images/linkedin.png"
                          />
                        </a>
                        <a
                          :href="skype"
                          class="d-block px-1"
                          target="_blank"
                          v-if="skype"
                        >
                          <img
                            max-width="32"
                            width="32"
                            height="32"
                            class="d-block mx-auto"
                            src="../../_core/images/skype.png"
                          />
                        </a>
                        <a
                        :href="'mailto:' + email"
                        class="d-block px-1"
                        v-if="email"
                      >
                          <img
                            max-width="32"
                            width="32"
                            height="32"
                            class="d-block mx-auto"
                            src="../../_core/images/email.png"
                          />
                        </a>
                      </div>

                      <h3
                        class="custom-danger--text fz-24 mt-0 pt-5 fw-900 mb-0 text-center"
                        v-if="city"
                      >
                        {{ city }}
                      </h3>

                      <h3
                        class="text-center mb-0 mt-4 fw-600 text-dark-standar fz-18"
                        v-if="scheduled"
                      >
                        Horario de atención
                        <br />
                        {{ scheduled }}
                      </h3>

                      <hr class="line-danger" v-if="phone" />

                      <h3
                        class="text-center my-0 fw-600 text-dark-standar fz-18"
                        v-if="phone"
                      >
                        Conmutador {{ phone }}
                      </h3>

                      <hr class="line-danger" />

                      <div
                        class="d-flex align-items-center flex-wrap justify-content-center mb-3 cursor-pointer"
                        v-if="list_address.length == 1"
                        @click="openGoogleMaps(list_address[0].google_maps_link)"
                      >
                        <v-icon color="custom-danger" class="w-auto h-auto fz-32">
                          mdi-map-marker
                        </v-icon>
                        <h3 class="text-center my-0 fw-600 text-dark-standar fz-18">
                          Ver en Google Maps
                        </h3>
                      </div>

                      <div
                        class="d-flex align-items-center flex-wrap justify-content-center mb-3 cursor-pointer"
                        v-if="list_address.length > 1"
                        @click="openAddress"
                      >
                        <v-icon color="custom-danger" class="w-auto h-auto fz-32">
                          mdi-map-marker
                        </v-icon>
                        <h3 class="text-center my-0 fw-600 text-dark-standar fz-18">
                          {{
                            list_address.length == 1
                              ? 'Ver en Google Maps'
                              : 'Ver listado de direcciones'
                          }}
                        </h3>
                      </div>

                      <h3
                        class="text-center my-0 fw-600 text-dark-standar fz-18"
                        v-if="list_address.length == 1"
                      >
                        {{ list_address[0].address }}
                      </h3>

                      <div class="d-flex justify-content-center mt-4" v-if="website">
                        <v-btn
                          color="custom-danger text-uppercase fw-600"
                          target="_blank"
                          :href="website"
                        >
                          Ver empresa
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_address[0]" width="560" v-if="list_address.length > 1">
          <v-card>
            <v-card-text class="scroll-modal-quotations">
              <ul
                class="mx-0 mt-3 mt-md-4 p-0 list-unstyled text-black fz-16 text-center"
              >
              <template v-for="(item, i) in list_address">
                <li :key="i" class="py-2">
                    <span class="fw-700">Dirección {{ i + 1 }}:</span>
                    <a
                      :href="item.google_maps_link"
                      target="_blank"
                      class="text-underline"
                    >
                      {{ item.address }}
                    </a>
                  </li>
                </template>
              </ul>

              <v-btn
                max-width="38"
                min-width="38"
                width="38"
                height="38"
                rounded
                color="custom-danger px-0"
                @click="closeAddress"
                class="mt-3 d-table mx-auto"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from '../../../core/helpers/File';
import UpdateController from '../controllers/UpdateController';

export default {
  name: 'UpdateCompany',
  data() {
    return {
      File,
      Helpers,
      form_valid: true,
      rules: {
        requerido: value => !!value || 'Campo obligatorio',
        url: value => this.validatedUrl(value) || 'URL no válida',
        email: value =>
          !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email incorrecto',
      },
      name: '',
      upload_photo: null,
      photo: '',
      list_address: [],
      scheduled: '',
      phone: '',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      email: '',
      city: '',
      url_company: '',
      url1: '',
      descripcion1: '',
      url2: '',
      descripcion2: '',
      url3: '',
      descripcion3: '',
      dialog_detail: {},
      dialog_address: {},
      update_controller: null,
    };
  },

  mounted() {
    this.update_controller = new UpdateController(this);
  },
  computed: {
  photoUrl() {
    return this.photo ? `URL de la foto: ${this.photo}` : 'No hay foto cargada';
  }
},
  methods: {

  updateCompany() {
  
  //console.log('Photo updated delmetodo:', this.photo);
  this.update_controller.updateCompany();

},
async uploadPhoto(file) {
    try {
      const result = await this.update_controller.uploadPhoto(file);
      //console.log('Resultado de uploadPhoto:', result);
      this.photo = result; // Actualiza la variable photo en el componente
      // Aquí puedes hacer lo que necesites con el resultado
    } catch (error) {
      //console.error('Error al cargar la foto:', error);

    }
  },

    addLocation() {
      this.update_controller.addLocation();
    },

    deleteLocation(position) {
      this.update_controller.deleteLocation(position);
    },

    deletePhoto() {
      this.update_controller.deletePhoto();
    },

    validatedUrl(str) {
      return this.update_controller.validatedUrl(str);
    },

    closeDetail() {
      this.update_controller.closeDetail();
    },

    openDialog() {
      this.update_controller.openDialog();
    },

    openAddress() {
      this.update_controller.openAddress();
    },

    closeAddress() {
      this.update_controller.closeAddress();
    },

    openGoogleMaps(google_maps) {
      this.update_controller.openGoogleMaps(google_maps);
    },
  },
};
</script>
