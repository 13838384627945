<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Portafolio</li>
            <li>Listado</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Listado de portafolio</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <div class="row">
              <div class="col-md-8 col-lg-9 col-xl-10">
                <v-text-field
                  filled
                  v-model="search_list"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  hide-details
                  clearable
                  class="my-0"
                ></v-text-field>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-2">
                <v-btn color="success" min-height="56" width="100%" href="#/briefcase/manage">Crear portafolio</v-btn>
              </div>
            </div>
            
            <div class="table-responsive mt-3 mt-md-4">
              <v-data-table
                class="my-0"
                :headers="headers"
                :items="lists"
                :items-per-page="5"
                :search="search_list"
              >
                <template #[`item.checkbox`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon color="success" v-bind="attrs" v-on="on" @click="toggleStateBriefcase(item.briefcase_id, lists.map(function(x) {return x.briefcase_id; }).indexOf(item.briefcase_id), item.state)">
                        <v-icon>{{item.state == 1 ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{item.state == 1 ? 'Deshabilitar' : 'Habilitar'}}</span>
                  </v-tooltip>
                </template>

                <template #[`item.photo`]="{ item }">
                  <img :src="File.fileUrlbrief(item.image)" class="d-block mx-auto" width="90" alt="" v-if="item.image">
                </template>

                <template #[`item.description`]="{ item }">
                  <div class="description">{{item.description.substring(0, 300)+'...'}}</div>
                </template>

                <template #[`item.actions`]="{ item }">
                  <div class="d-flex align-items-center justify-content-center flex-nowrap">
                    <!-- Detalle -->
                    <v-tooltip bottom v-if="item.state == 1">
                      <template #activator="{ on, attrs }">
                        <v-btn icon color="secondary" v-bind="attrs" v-on="on" href="#/web/briefcase" target="_blank">
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Detalle</span>
                    </v-tooltip>

                    <!-- Editar -->
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on" :href="'#/briefcase/manage/'+item.briefcase_id">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import ListController from '../controllers/ListController';

export default {
  name: 'BriefcaseList', // Nombre de componente multi-palabra añadido

  data() {
    return {
      Helpers,
      File,
      headers: [],
      lists: [],
      search_list: '',

      list_controller: null
    };
  },

  mounted() {
    this.list_controller = new ListController(this);
  },

  methods: {
    toggleStateBriefcase(briefcase_id, position, state) {
      console.log ('briefcasep', briefcase_id);
      console.log ('position', position);
      console.log ('state', state);
      console.log ('Entra por aquí');
      this.list_controller.toggleStateBriefcase(briefcase_id, position, state);
    }
  }
}
</script>