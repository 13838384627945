import Rest from '../../../core/libs/Rest';
import { MAIN_MOCKUP } from '../mockups/Main';

class MainRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoProfile(userId) {
    console.log('MainRepository.infoProfile llamado con userId:', userId);
    this.rest.setParams('');
    if (userId && userId !== 'current' && userId !== '{userId}') {
      this.rest.setEndPoint(`/api/card/contact/show-contact/${userId}`);
    } else {
      this.rest.setEndPoint('/api/card/contact/my-profile');
    }
    console.log('MainRepository.infoProfile - Endpoint:', this.rest.endPoint);
    return this.rest.get();
  }
}

export default MainRepository;